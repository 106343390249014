<template>
    <div class="TalentRecruitmentList">
      <div class="head f-c-b">
        <div class="LeftPart f-c">
          <img class="logo" src="@/assets/Community/logo.png" alt="">
          <img class="shiliaologo" src="@/assets/Community/shiliaologo.png" alt="">
        </div>
        <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">
            <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
            <span> 当前位置： 首页</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/TalentRecruitmentIndex' }">人才招聘</el-breadcrumb-item>
          <el-breadcrumb-item>人才市场</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="main f-cl-c-c">
        <div class="searchBox flex">
          <input type="text" v-model.trim="positionSearch" @keyup.enter="search" />
          <img @click="search" src="@/assets/Community/search.png" alt="">
        </div>
        <div class="list">
          <div class="item f-cl-b" v-for="(item, index) of list" :key="index" @click="toJobdetail(item.resumeId)">
             <div class="f-c-b">
                <div>
                    <div class="flex">
                        <div class="name mr-80">{{ item.name }}</div>
                        <div class="f-c">
                            <img class="mr-10" src="@/assets/Community/jobIcon.png" alt="jobIcon">
                            <div class="position w-300">{{ item.position }}</div>
                        </div>
                    </div>
                    <div class="f-c mt-30">
                        <span>{{ item.workAge }}</span>
                        <span class="ml-10 mr-10">|</span>
                        <span>{{ item.topDegree ? item.topDegree : '未填写' }}</span>
                        <span class="ml-10 mr-10">|</span>
                        <span>{{ item.salary }}</span>
                    </div>
                </div>
                <img src="@/assets/Community/head.png" alt="head">
             </div>
          </div>
        </div>
      </div>
      <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout" @pagination="fetchData" />
    </div>
  </template>
  
  <script>
  import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
  export default {
    name: 'TalentRecruitmentList',
    mixins: [PaginationRetention],
    // components: {
    //   PositionItem: () => import('./components/PositionItem.vue')
    // },
    data() {
      return {
        list: [],
        positionSearch:'',
      }
    },
    created() { },
    methods: {
      getDate() {
        this.forumResumeList(this.paging, this.pagingSize)
      },
      // 获取参数
      getParams(page, size) {
        let that = this;
        return {
          userId: parseInt(that.$store.state.userId),
          token: that.$getStorage('token'),
          positionSearch: that.positionSearch,
          workLocation: '',
          page,
          size
        }
      },
      // 职位招聘帖子列表接口
      forumResumeList(page = this.page, size = this.size) {
        let that = this;
        that.$http.forumResumeList(that.getParams(page, size)).then(res => {
          if (res.code == 200) {
            that.list = res.data.records
            that.currentPage = res.data.current;//当前页数
            that.total = res.data.total
          }
        })
      },
      toJobdetail(data){
        this.$router.push({
            path: '/TalentRecruitmentDetail',
            query: {
              key: this.$UrlEncode.encode(JSON.stringify({
                type: 'detail',
                id: data
              }))
            }
          })
      },
      search(){
        this.forumResumeList(1, 10)
      }
  
    },
  }
  
  </script>
  <style lang='less' scoped>
  .TalentRecruitmentList {
    .head {
      padding: 20px 0;
      border-bottom: 1px solid #F7F7F8;
  
      .LeftPart {
        width: 55px;
        cursor: pointer;
  
        .logo {
          width: 53px;
          height: 53px;
          margin-right: 18px;
        }
  
        .shiliaologo {
          width: 77px;
          height: 38px;
        }
  
      }
  
      .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
      }
  
      .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
      }
    }
  
    .main {
      .searchBox {
        width: 790px;
        height: 60px;
        // border: 1px solid #0363FA;
  
        input {
          background-color: #FFF;
          border-radius: 4px 0 0 4px;
          border: 1px solid #E0E1E3;
          border-right: none;
          box-sizing: border-box;
          color: #606266;
          display: inline-block;
          height: 60px;
          line-height: 60px;
          outline: 0;
          padding: 10px;
          transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
          width: 660px;
          font-size: 24px;
        }
  
        img {
          cursor: pointer;
        }
      }
  
      .list {
        margin-top: 35px;
        width: 790px;
  
        .item {
          cursor: pointer;
          height: 100px;
          border-radius: 20px;
          border: 1px solid #C4C4C4;
          margin-bottom: 30px;
          padding: 30px 50px;
  
          .name {
            font-size: 28px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            color: #333333;
          }
          .position{
            font-size: 20px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            color: #333333;
          }
          span {
            font-size: 18px;
            color: #666666;
            line-height: 20px;
          }
  

        }
      }
    }
  }
  </style>
  
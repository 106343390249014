import { render, staticRenderFns } from "./TalentMarketList.vue?vue&type=template&id=1278feea&scoped=true&"
import script from "./TalentMarketList.vue?vue&type=script&lang=js&"
export * from "./TalentMarketList.vue?vue&type=script&lang=js&"
import style0 from "./TalentMarketList.vue?vue&type=style&index=0&id=1278feea&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1278feea",
  null
  
)

export default component.exports